/* istanbul ignore file: will be tested later */
import {RichContent} from 'ricos-schema';
import {isRichContent} from 'ricos-content';

export const isRicoRichContent = (content: string) => {
  try {
    const richContent = JSON.parse(content) as RichContent;
    return isRichContent(richContent);
  } catch {
    return false;
  }
};
